@import '../../../styles/variables';

.reviews {
    width: 100%;
    padding: 120px 0;
    background-color: $gray-7;
    position: relative;
    overflow: hidden;

    &::before {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        height: 1px;
        background: linear-gradient(90deg, transparent 0%, rgba(234, 88, 12, 0.3) 50%, transparent 100%);
    }

    @media screen and (max-width: $tablet-medium-screen) {
        padding: 80px 0;
    }
}

.content {
    display: flex;
    flex-direction: column;
    align-items: center;
    // text-align: center;
}

.subtitle {
    color: rgba(234, 88, 12, 1);
    font-size: 14px;
    font-weight: 600;
    letter-spacing: 1.4px;
    text-transform: uppercase;
    margin-bottom: 16px;
}

.title {
    color: #E7E5E4;
    font-size: 36px;
    font-family: Sora, sans-serif;
    font-weight: 600;
    line-height: 110%;
    letter-spacing: -0.5px;
    margin-bottom: 64px;

    @media screen and (max-width: $tablet-medium-screen) {
        font-size: 32px;
        margin-bottom: 48px;
    }
}

.grid {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 24px;
    width: 100%;

    @media screen and (max-width: $desktop-screen) {
        grid-template-columns: repeat(2, 1fr);
    }

    @media screen and (max-width: $tablet-medium-screen) {
        grid-template-columns: 1fr;
        max-width: 500px;
        margin: 0 auto;
    }
}

.reviewCard {
    background: rgba(255, 255, 255, 0.03);
    border-radius: 12px;
    padding: 32px;
    display: flex;
    flex-direction: column;
    text-align: left;
    transition: transform 0.3s ease;

    &:hover {
        transform: translateY(-4px);
    }
}

.quoteIcon {
    margin-bottom: 16px;

    .icon {
        width: 24px;
        height: 24px;
        color: rgba(234, 88, 12, 0.3);
    }
}

.content {
    color: #E4E4E7;
    font-size: 16px;
    line-height: 150%;
    letter-spacing: -0.16px;
    margin-bottom: 24px;
    flex-grow: 1;
}

.author {
    display: flex;
    align-items: center;
    gap: 12px;
    margin-top: auto;
}

.avatarWrapper {
    width: 40px;
    height: 40px;
    border-radius: 50%;
    overflow: hidden;
}

.avatar {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.authorInfo {
    display: flex;
    flex-direction: column;
    gap: 4px;
}

.name {
    color: #E7E5E4;
    font-size: 14px;
    font-weight: 600;
    line-height: 1;
}

.github {
    color: rgba(234, 88, 12, 1);
    font-size: 14px;
    text-decoration: none;
    transition: opacity 0.2s ease;

    &:hover {
        opacity: 0.8;
    }
}

.projectReviewsSection {
    margin-top: 120px;
    width: 100%;

    @media screen and (max-width: $tablet-medium-screen) {
        margin-top: 80px;
    }
}

.projectReviewsTitle {
    color: #E7E5E4;
    font-size: 28px;
    font-family: Sora, sans-serif;
    font-weight: 600;
    line-height: 110%;
    letter-spacing: -0.5px;
    margin-bottom: 16px;
}

.projectReviewsSubtitle {
    color: #E4E4E7;
    font-size: 16px;
    line-height: 150%;
    letter-spacing: -0.16px;
    margin-bottom: 48px;
    max-width: 600px;
    margin-left: auto;
    margin-right: auto;
}

.projectReviewsGrid {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 24px;
    margin-bottom: 40px;

    @media screen and (max-width: $tablet-medium-screen) {
        grid-template-columns: 1fr;
    }
}

.projectReviewCard {
    background: rgba(255, 255, 255, 0.03);
    border-radius: 12px;
    overflow: hidden;
    transition: transform 0.3s ease;

    &:hover {
        transform: translateY(-4px);
    }

    // Override UserReview component styles for dark theme
    :global {
        .css-* {
            // Replace with your actual UserReview component class
            background: transparent;
            border: none;
        }
    }
}

.pagination {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 16px;
    margin-top: 32px;
}

.paginationButton {
    background: rgba(234, 88, 12, 0.1);
    border: 1px solid rgba(234, 88, 12, 0.2);
    color: rgba(234, 88, 12, 1);
    border-radius: 8px;
    width: 40px;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    transition: all 0.2s ease;

    &:hover:not(:disabled) {
        background: rgba(234, 88, 12, 0.2);
    }

    &:disabled {
        opacity: 0.5;
        cursor: not-allowed;
    }
}

.paginationInfo {
    color: #E4E4E7;
    font-size: 14px;
    font-weight: 500;
}