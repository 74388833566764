@import '../../../styles/variables';

.statistics {
    width: 100%;
    padding: 160px 0;
    background: linear-gradient(180deg, $gray-7 0%, $gray-7 100%);
    position: relative;
    overflow: hidden;

    &::before {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        height: 1px;
        background: linear-gradient(90deg, transparent 0%, rgba(234, 88, 12, 0.3) 50%, transparent 100%);
    }

    @media screen and (max-width: $desktop-screen) {
        padding: 120px 0;
    }

    @media screen and (max-width: $tablet-medium-screen) {
        padding: 100px 0;
    }

    @media screen and (max-width: $tablet-medium-screen) {
        padding: 80px 0;
    }
}

.content {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
}

.title {
    color: #E7E5E4;
    font-size: 36px;
    font-family: Sora, sans-serif;
    font-weight: 600;
    line-height: 110%;
    letter-spacing: -0.5px;
    margin-bottom: 64px;

    @media screen and (max-width: $tablet-medium-screen) {
        font-size: 32px;
        margin-bottom: 48px;
    }
}

.statsGrid {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    gap: 64px;
    width: 100%;
    max-width: 1200px;
    margin: 0 auto;

    @media screen and (max-width: $desktop-screen) {
        grid-template-columns: repeat(2, 1fr);
        gap: 48px;
        max-width: 600px;
    }

    @media screen and (max-width: $tablet-medium-screen) {
        grid-template-columns: 1fr;
        gap: 48px;
        max-width: 400px;
    }
}

.statItem {
    display: flex;
    flex-direction: column;
    align-items: center;
    position: relative;

    &:not(:last-child)::after {
        content: '';
        position: absolute;
        right: -32px;
        top: 0;
        bottom: 0;
        width: 1px;
        background: linear-gradient(180deg, transparent 0%, rgba(234, 88, 12, 0.2) 50%, transparent 100%);

        @media screen and (max-width: $tablet-medium-screen) {
            display: none;
        }
    }
}

.statNumber {
    color: rgba(234, 88, 12, 1);
    font-size: 48px;
    font-weight: 700;
    line-height: 1;
    margin-bottom: 16px;
    font-family: Sora, sans-serif;
    position: relative;

    &::after {
        content: '';
        position: absolute;
        bottom: -8px;
        left: 50%;
        transform: translateX(-50%);
        width: 40px;
        height: 2px;
        background: rgba(234, 88, 12, 0.3);
        border-radius: 1px;
    }

    @media screen and (max-width: $tablet-medium-screen) {
        font-size: 42px;
    }
}

.statLabel {
    color: #E4E4E7;
    font-size: 18px;
    line-height: 150%;
    letter-spacing: -0.18px;
    max-width: 200px;

    @media screen and (max-width: $tablet-medium-screen) {
        font-size: 16px;
    }
}