@import '../../../styles/variables';

.img-wrapper {
  width: 100%;
  position: relative;
  margin: 0 auto;
  display: flex;
  justify-content: center;

  .img-background {
    max-width: 390px;
    width: 100%;
    height: auto;

    @media screen and (max-width: $mobile-large-screen) {
      max-width: 100%;
    }
  }

  .img-background2 {
    width: 502px;
    height: auto;

    @media screen and (max-width: 48em) {
      max-width: 100%;
      width: 100%;
    }
  }

  .img-gitflow {
    width: 100%;
    max-width: 258px;
    height: auto;
    position: absolute;
    top: 40%;
    left: 40%;

    @media screen and (max-width: $tablet-large-screen) {
      left: 25%;
    }

    @media screen and (max-width: $tablet-medium-screen) {
      left: 30%;
    }

    @media screen and (max-width: $mobile-small-screen) {
      max-width: 218px;
      top: 35%;
      left: 25%;
    }

    @media screen and (max-width: $mobile-extra-small-screen) {
      max-width: 160px;
      top: 40%;
      left: 30%;
    }
  }

  .img-aipowered {
    width: 100%;
    max-width: 358px;
    height: auto;
    position: absolute;
    top: 50%;
    left: 50%;

    @media screen and (max-width: $tablet-medium-screen) {
      max-width: 318px;
      left: 30%;
    }

    @media screen and (max-width: $tablet-small-screen) {
      top: 50%;
      left: 25%;
    }

    @media screen and (max-width: $mobile-medium-screen) {
      top: 45%;
      left: 10%;
    }

    @media screen and (max-width: $mobile-small-screen) {
      max-width: 288px;
      top: 60%;
      left: 15%;
    }

    @media screen and (max-width: $mobile-extra-small-screen) {
      max-width: 218px;
      top: 60%;
      left: 10%;
    }
  }

  .img-gamification1 {
    width: 100%;
    max-width: 216px;
    height: auto;
    position: absolute;
    top: 55%;
    right: 55%;

    @media screen and (max-width: $mobile-large-screen) {
      max-width: 195px;
    }

    @media screen and (max-width: $mobile-medium-screen) {
      max-width: 185px;
      top: 40%;
      right: 50%;
    }

    @media screen and (max-width: $mobile-small-screen) {
      max-width: 175px;
      top: 30%;
      right: 5%;
    }
  }

  .img-gamification2 {
    width: 100%;
    max-width: 244px;
    height: auto;
    position: absolute;
    top: 40%;
    right: 60%;

    @media screen and (max-width: $mobile-large-screen) {
      max-width: 212px;
    }

    @media screen and (max-width: $mobile-medium-screen) {
      max-width: 198px;
      top: 25%;
      right: 50%;
    }

    @media screen and (max-width: $mobile-small-screen) {
      max-width: 178px;
      right: 0;
      top: 15%;
    }
  }

  .img-gamification3 {
    width: 100%;
    max-width: 252px;
    height: auto;
    position: absolute;
    top: 15%;
    right: 50%;

    @media screen and (max-width: $mobile-large-screen) {
      max-width: 234px;
    }

    @media screen and (max-width: $mobile-medium-screen) {
      max-width: 214px;
      top: 5%;
      right: 40%;
    }

    @media screen and (max-width: $mobile-small-screen) {
      max-width: 198px;
      right: 5%;
      top: -5%;
    }
  }

  .img-gamification4 {
    width: 100%;
    max-width: 232px;
    height: auto;
    position: absolute;
    top: 45%;
    left: 50%;

    @media screen and (max-width: $tablet-large-screen) {
      max-width: 208px;
      top: 45%;
      left: 40%;
    }

    @media screen and (max-width: 880px) {
      max-width: 198px;
      top: 45%;
      left: 40%;
    }

    @media screen and (max-width: $tablet-medium-screen) {
      max-width: 218px;
      top: 45%;
      left: 45%;
    }

    @media screen and (max-width: $mobile-medium-screen) {
      max-width: 198px;
      top: 65%;
      left: 40%;
    }

    @media screen and (max-width: $mobile-small-screen) {
      max-width: 178px;
      top: 65%;
      left: 0;
    }
  }

  .img-discord {
    width: 100%;
    max-width: 41px;
    height: auto;
    position: absolute;
    top: 35%;
    left: 60%;
    z-index: 10;

    @media screen and (max-width: $mobile-large-screen) {
      left: 60%;
    }

    @media screen and (max-width: $mobile-medium-screen) {
      left: 40%;
    }

    @media screen and (max-width: $mobile-small-screen) {
      left: 40%;
    }

    @media screen and (max-width: $mobile-extra-small-screen) {
      max-width: 31px;
    }
  }

  .img-weekly1 {
    width: 100%;
    max-width: 166px;
    height: auto;
    position: absolute;
    top: 40%;
    left: 65%;

    @media screen and (max-width: $mobile-large-screen) {
      left: 60%;
    }

    @media screen and (max-width: $mobile-medium-screen) {
      left: 45%;
    }

    @media screen and (max-width: $mobile-extra-small-screen) {
      max-width: 145px;
      left: 40%;
    }
  }

  .img-weekly2 {
    width: 100%;
    max-width: 166px;
    height: auto;
    position: absolute;
    top: 52%;
    left: 50%;

    @media screen and (max-width: $mobile-large-screen) {
      left: 50%;
    }

    @media screen and (max-width: $mobile-medium-screen) {
      left: 50%;
    }

    @media screen and (max-width: $mobile-medium-screen) {
      left: 40%;
    }

    @media screen and (max-width: $mobile-extra-small-screen) {
      max-width: 145px;
    }
  }

  .img-weekly3 {
    width: 100%;
    max-width: 166px;
    height: auto;
    position: absolute;
    top: 60%;
    left: 50%;

    @media screen and (max-width: $mobile-medium-screen) {
      left: 50%;
      top: 61%;
    }

    @media screen and (max-width: $mobile-medium-screen) {
      left: 40%;
    }

    @media screen and (max-width: $mobile-extra-small-screen) {
      max-width: 145px;
    }
  }

  .img-weekly4 {
    width: 100%;
    max-width: 166px;
    height: auto;
    position: absolute;
    top: 68%;
    left: 50%;

    @media screen and (max-width: $mobile-large-screen) {
      left: 50%;
    }

    @media screen and (max-width: $mobile-medium-screen) {
      left: 50%;
      top: 70%;
    }

    @media screen and (max-width: $mobile-medium-screen) {
      left: 40%;
    }

    @media screen and (max-width: $mobile-extra-small-screen) {
      max-width: 145px;
    }
  }

  .img-paircoding {
    width: 100%;
    max-width: 276px;
    height: auto;
    position: absolute;
    top: 40%;
    left: 30%;

    @media screen and (max-width: $tablet-large-screen) {
      left: 15%;
    }

    @media screen and (max-width: $tablet-medium-screen) {
      left: 30%;
    }

    @media screen and (max-width: $mobile-medium-screen) {
      left: 20%;
    }

    @media screen and (max-width: $mobile-small-screen) {
      max-width: 216px;
    }

    @media screen and (max-width: $mobile-extra-small-screen) {
      max-width: 196px;
    }
  }
}

.videoWrapper {
  position: relative;
  width: 502px;
  height: 293px;
  border-radius: 12px;
  overflow: hidden;

  iframe {
    border: none;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }

  @media screen and (max-width: 48em) {
    max-width: 100%;
    width: 100%;
    height: auto;
    aspect-ratio: 502/293;

    iframe {
      position: absolute;
      width: 100%;
      height: 100%;
    }
  }
}

$mobile-extra-small-screen: 22.4em; //<360px
$mobile-small-screen: 23.4em; //385px
$mobile-medium-screen: 30em; //480px
$mobile-large-screen: 36em; //576px
$tablet-small-screen: 39em; //<640px
$tablet-medium-screen: 48em; //768px
$tablet-large-screen: 64em; //1024px
$desktop-screen: 75em; //1200px