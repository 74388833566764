@import '../../styles/variables';

.singleProgram {
  display: grid;
  grid-template-columns: 1fr 1fr;
  border-radius: 4px;
  border: 1px solid var(--gray-8, #222225);
  padding: 50px 40px;
  row-gap: 24px;
  column-gap: 52px;
  cursor: pointer;
  background: #1C1C1C;
  border: 1px solid rgba(234, 88, 12, 0.1);
  border-radius: 12px;
  overflow: hidden;
  transition: all 0.2s ease-in-out;

  @media screen and (max-width: $tablet-medium-screen) {
    grid-template-columns: 1fr;
    padding: 16px;
  }

  @media screen and (max-width: $mobile-small-screen) {
    grid-template-columns: 1fr;
    padding: 16px;
  }

  &:hover {
    // border-color: rgba(234, 88, 12, 0.3);
    transform: translateY(-2px);
  }
}

.nav {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  align-content: flex-start;
  gap: 8px;
  margin-bottom: 16px;


  @media screen and (max-width: $tablet-large-screen) {
    flex-wrap: wrap;
    // flex-direction: column-reverse;
  }
}

.chip {
  display: inline-flex;
  align-items: center;
  gap: 8px;
  padding: 4px 12px;
  border-radius: 4px;
  background: rgba(234, 88, 12, 0.1);
  color: #EA580C;
  font-size: 14px;
  font-weight: 600;
  white-space: nowrap;
  margin-right: 8px;
}

.currentPrice {
  color: #EA580C;
  font-weight: 600;
}

.originalPrice {
  text-decoration: line-through;
  color: rgba(234, 88, 12, 0.6);
  font-size: 12px;
}

.discountBadge {
  background: #EA580C;
  color: white;
  padding: 2px 6px;
  border-radius: 4px;
  font-size: 12px;
  font-weight: 600;
}

.highlightedPill {
  display: inline-flex;
  align-items: center;
  padding: 4px 12px;
  border-radius: 4px;
  background: rgba(234, 88, 12, 0.1);
  color: #EA580C;
  font-size: 14px;
  font-weight: 600;
  white-space: nowrap;
}

.discount {
  font-size: 12px;
  text-decoration: line-through;
}

.title {
  color: var(--gray-11, #D6D3D1);
  font-size: 24px;
  font-weight: 600;
  line-height: 110%;
  letter-spacing: -1.36px;
  margin-bottom: 12px;
}

.shortDescription {
  color: var(--gray-11, #D6D3D1);
  /* Header 6 */
  font-size: 16px;
  line-height: 1.5;
  color: #A8A29E;
  margin-bottom: 24px;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  overflow: hidden;
}

.imageWrapper {
  width: 100%;
  overflow: hidden;
  position: relative;

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
}

.programData {
  padding: 24px;
}

@media (max-width: 768px) {
  .nav {
    flex-direction: column;
    align-items: stretch;
    gap: 12px;

    >div {
      // justify-content: center;
    }
  }

  .chip,
  .highlightedPill {
    font-size: 12px;
    padding: 4px 8px;
  }

  .title {
    font-size: 20px;
  }

  .shortDescription {
    font-size: 14px;
  }
}