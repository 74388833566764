@import '../../../styles/variables';

.faq {
  padding: 5rem 0 5rem;
  background-color: $white;

  @media screen and (max-width: $tablet-large-screen) {
    padding: 5rem 0 5rem;
  }

  @media screen and (max-width: $tablet-medium-screen) {
    padding: 5rem 0 5rem;
  }

  @media screen and (max-width: $mobile-medium-screen) {
    padding: 5rem 0 5rem;
  }

  .wrapper {
    max-width: $max-width;
    margin: 0 auto;
    padding: 5rem 1rem;
    display: flex;
    justify-content: center;
    gap: 4rem;

    @media screen and (max-width: $tablet-large-screen) {
      gap: 2rem;
    }

    @media screen and (max-width: $tablet-medium-screen) {
      flex-direction: column;
      padding: 3rem 1rem;
    }
  }

  .container {
    display: flex;
    flex-direction: column;
    gap: 1rem;
  }

  .sticky {
    position: sticky;
    top: 90px;

    @media screen and (max-width: tablet-medium-screen) {
      position: relative;
    }
  }

  .subheader {
    color: $gray-3;
    @include subheader-3;
  }

  .title {
    @include header-3;
  }

  .subtitle {
    max-width: 300px;
    color: $gray-3;
    @include subheader-4;
  }

  .content {
    display: flex;
    flex-direction: column;
    max-width: 600px;
    width: 100%;
    gap: 1rem;

    @media screen and (max-width: $mobile-medium-screen) {
      gap: 0.5rem;
    }
  }

  .contact-section {
    display: flex;
    flex-direction: column;
    margin-top: 4rem;
    padding-top: 3rem;
    border-top: 1px solid rgba(0, 0, 0, 0.1);
  }

  .contact-subtitle {
    font-size: 1.125rem;
    margin-bottom: 0.5rem;
  }

  .contact-title {
    font-size: 2rem;
    font-weight: 700;
    margin: 0 0 0.5rem;
  }

  .contact-description {
    font-size: 1.125rem;
    margin-bottom: 2rem;
    color: $gray-3;
  }

  .contact-info {
    display: flex;
    align-items: center;
    gap: 1.5rem;
  }

  .contact-avatar {
    width: 6rem;
    height: 6rem;
    border-radius: 50%;
    background-color: #f0f0f0;
    background-image: url('/images/landing/jakub-with-board.webp');
    background-size: cover;
    background-position: center;

    @media screen and (max-width: $mobile-medium-screen) {
      width: 4.5rem;
      height: 4.5rem;
    }
  }

  .contact-details {
    display: flex;
    flex-direction: column;
  }

  .contact-name {
    font-size: 1.25rem;
    font-weight: 700;
    margin: 0 0 0.25rem;
  }

  .contact-role {
    font-size: 1rem;
    color: $gray-3;
    margin: 0 0 0.5rem;
  }

  .contact-email,
  .contact-phone {
    color: $orange-1;
    text-decoration: none;
    font-size: 1rem;
    margin-bottom: 0.25rem;
    font-weight: 500;

    &:hover {
      text-decoration: underline;
    }
  }
}