@import '../../../styles/variables';

.whoWeAre {
  padding: 5rem 0 154px;

  @media screen and (max-width: $mobile-large-screen) {
    padding: 5rem 0 54px;
  }

  @media screen and (max-width: $mobile-small-screen) {
    padding: 5rem 0 54px;
  }
}

.blogPostsGrid {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 30px;
  margin-top: 2rem;

  @media screen and (max-width: $mobile-large-screen) {
    grid-template-columns: repeat(1, 1fr);
  }

  @media screen and (max-width: $mobile-small-screen) {
    grid-template-columns: repeat(1, 1fr);
  }
}

.wrapper {
  max-width: $max-width;
  margin: 0 auto;
}

.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  gap: 1rem;
  padding: 0 1rem 2rem;
}

.subtitle {
  color: $gray-2;
  @include caption-m;
}

.title {
  color: $gray-2;
  @include header-2;

  @media screen and (max-width: $mobile-large-screen) {
    @include special-header-3;
  }
}

.subheader {
  max-width: 43em;
  text-align: center;
  color: $gray-3;
  @include subheader-4;

  @media screen and (max-width: $mobile-large-screen) {
    @include body-2;
  }
}

.content {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 4rem;
  margin-top: 3rem;
  align-items: start;

  @media screen and (max-width: $mobile-large-screen) {
    grid-template-columns: 1fr;
    gap: 2rem;
  }
}

.leftColumn {
  display: flex;
  flex-direction: column;
  gap: 2rem;

  .imageWrapper {
    width: 100%;

    img {
      width: 100%;
      height: auto;
      border-radius: 12px;
      object-fit: cover;
    }
  }
}

.rightColumn {
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: flex-start;

  .description {
    @include subheader-4;
    color: $gray-3;
    margin-bottom: 2rem;
  }
}

.foundersDetails {
  display: flex;
  flex-direction: column;
  gap: 2rem;
}

.divider {
  width: 100%;
  height: 1px;
  background-color: $gray-2;
  opacity: 0.1;
  margin: 0.5rem 0;
}

.founder {
  text-align: left;
  padding: 1rem 0;

  h3 {
    @include header-4;
    margin-bottom: 0.75rem;
    color: $gray-1;
  }

  .role {
    @include body-1;
    color: $gray-1;
    font-weight: 600;
    margin-bottom: 0.5rem;
  }

  .bio {
    @include body-1;
    color: $gray-3;
    margin-bottom: 1rem;
  }

  .initiatives {
    margin: 1.25rem 0;

    p {
      @include body-2;
      color: $gray-3;
      margin-bottom: 0.5rem;
      line-height: 1.6;
    }
  }

  .companyLink {
    color: $orange-1;
    text-decoration: none;
    font-weight: 500;

    &:hover {
      text-decoration: underline;
    }
  }

  .linkedinLink {
    @include body-1;
    display: inline-block;
    color: $orange-1;
    text-decoration: none;
    font-weight: 500;
    margin-top: 0.75rem;

    &:hover {
      text-decoration: underline;
    }
  }
}

@media screen and (max-width: $mobile-large-screen) {
  .content {
    grid-template-columns: 1fr;
    gap: 3rem;
  }

  .foundersDetails {
    flex-direction: column;
    gap: 2rem;
  }
}